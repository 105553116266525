.nk-blockquote {
    position: relative;
    margin: 20px 0;
    margin-top: 40px;
    z-index: 1;

    .nk-blockquote-icon {
        position: absolute;
        top: -35px;
        left: -5px;
        font-family: "Times New Roman", Times, serif;
        font-size: 150px;
        font-weight: 600;
        line-height: 135px;
        color: #aaa;
        opacity: .2;
        z-index: -1;
    }

    .nk-blockquote-author {
        margin-top: 5px;
        font-weight: 600;
        text-align: right;
    }

    > a {
        color: inherit;
        text-decoration: none;
    }

    em {
        display: block;
        margin-left: 20px;
    }
}

.nk-blockquote-styled {
    position: relative;
    margin: 20px 0;
    overflow: hidden;
    background-color: $color_dark_1;

    .nk-blockquote-cont {
        position: relative;
        display: block;
        padding: 60px;
        color: #fff;
        z-index: 1;

        .nk-blockquote-icon {
            top: 50px;
            left: 40px;
            line-height: 100px;
            color: #fff;
        }

        em {
            margin-left: 0;
            font-size: 1.7rem;
        }
    }
}
