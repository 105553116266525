@import "variables";

/*------------------------------------------------------------------
  [Typography]

  Body:     #{$html_font_size}/#{$body_line_height} #{$font_body};
  Headings: #{$font_headings}

  Note: Most of font sizes are relative to the base font size (rem)
 -------------------------------------------------------------------*/

/*------------------------------------------------------------------
  [Color codes]

  Main 1:    #{$color_main_1}
  Main 2:    #{$color_main_2}
  Main 3:    #{$color_main_3}
  Main 4:    #{$color_main_4}
  Main 5:    #{$color_main_5}

  Dark 1:    #{$color_dark_1}
  Dark 2:    #{$color_dark_2}
  Dark 3:    #{$color_dark_3}
  Dark 4:    #{$color_dark_4}

  Gray 1:    #{$color_gray_1}
  Gray 2:    #{$color_gray_2}
  Gray 3:    #{$color_gray_3}
  Gray 4:    #{$color_gray_4}

  Bootstrap Primary:  #{$color_primary}
  Bootstrap Success:  #{$color_success}
  Bootstrap Info:     #{$color_info}
  Bootstrap Warning:  #{$color_warning}
  Bootstrap Danger:   #{$color_danger}
 -------------------------------------------------------------------*/

/*------------------------------------------------------------------
  [Table of contents]
  {{table_of_contents}}
 -------------------------------------------------------------------*/


/*------------------------------------------------------------------

  Base

 -------------------------------------------------------------------*/
@import "parts/base";

/*------------------------------------------------------------------

  Typography

 -------------------------------------------------------------------*/
@import "parts/typography";

/*------------------------------------------------------------------

  Bootstrap

 -------------------------------------------------------------------*/
@import "parts/bootstrap";

/*------------------------------------------------------------------

  Helpers

 -------------------------------------------------------------------*/
@import "parts/helpers";

// Layouts

/*------------------------------------------------------------------

  Page Border

 -------------------------------------------------------------------*/
@import "parts/page-border";

/*------------------------------------------------------------------

  Share Place

 -------------------------------------------------------------------*/
@import "parts/share-place";

/*------------------------------------------------------------------

  Navbar

 -------------------------------------------------------------------*/
@import "parts/navbar";

/*------------------------------------------------------------------

  Navbar Side

 -------------------------------------------------------------------*/
@import "parts/navbar-side";

/*------------------------------------------------------------------

  Navbar Full

 -------------------------------------------------------------------*/
@import "parts/navbar-full";

/*------------------------------------------------------------------

  Search

 -------------------------------------------------------------------*/
@import "parts/search";

/*------------------------------------------------------------------

  Header Title

 -------------------------------------------------------------------*/
@import "parts/header-title";

/*------------------------------------------------------------------

  Footer

 -------------------------------------------------------------------*/
@import "parts/footer";

/*------------------------------------------------------------------

  Side Buttons

 -------------------------------------------------------------------*/
@import "parts/side-buttons";

/*------------------------------------------------------------------

  Quick View

 -------------------------------------------------------------------*/
@import "parts/quick-view";

/*------------------------------------------------------------------

  Page Nav

 -------------------------------------------------------------------*/
@import "parts/page-nav";

/*------------------------------------------------------------------

  Widgets

 -------------------------------------------------------------------*/
@import "parts/widgets";

/*------------------------------------------------------------------

  Elements

 -------------------------------------------------------------------*/

/*---------------------------
  Element Buttons
 ----------------------------*/
@import "parts/element-buttons";

/*---------------------------
  Element Progress
 ----------------------------*/
@import "parts/element-progress";

/*---------------------------
  Element Tabs
 ----------------------------*/
@import "parts/element-tabs";

/*---------------------------
  Element Accordion
 ----------------------------*/
@import "parts/element-accordion";

/*---------------------------
  Element Icon Boxes
 ----------------------------*/
@import "parts/element-icon-boxes";

/*---------------------------
  Element Content Boxes
 ----------------------------*/
@import "parts/element-content-boxes";

/*---------------------------
  Element Counters
 ----------------------------*/
@import "parts/element-counters";

/*---------------------------
  Element Dividers
 ----------------------------*/
@import "parts/element-dividers";

/*---------------------------
  Element Dropcaps
 ----------------------------*/
@import "parts/element-dropcaps";

/*---------------------------
  Element Pagination
 ----------------------------*/
@import "parts/element-pagination";

/*---------------------------
  Element Breadcrumbs
 ----------------------------*/
@import "parts/element-breadcrumbs";

/*---------------------------
  Element Pricing
 ----------------------------*/
@import "parts/element-pricing";

/*---------------------------
  Element Testimonials
 ----------------------------*/
@import "parts/element-testimonials";

/*---------------------------
  Element Blockquotes
 ----------------------------*/
@import "parts/element-blockquotes";

/*---------------------------
  Element Info Boxes
 ----------------------------*/
@import "parts/element-info-boxes";

/*---------------------------
  Element Carousels
 ----------------------------*/
@import "parts/element-carousels";

/*---------------------------
  Element Image Boxes
 ----------------------------*/
@import "parts/element-image-boxes";

/*---------------------------
  Element Video
 ----------------------------*/
@import "parts/element-video";

/*---------------------------
  Element Gif
 ----------------------------*/
@import "parts/element-gif";

/*---------------------------
  Element Forms
 ----------------------------*/
@import "parts/element-forms";

/*---------------------------
  Element Audio Player
 ----------------------------*/
@import "parts/element-audio-player";

/*---------------------------
  Element Countdown
 ----------------------------*/
@import "parts/element-countdown";

/*---------------------------
  Element Text Typed
 ----------------------------*/
@import "parts/element-text-typed";

/*---------------------------
  Element Link Effects
 ----------------------------*/
@import "parts/link-effects";

/*---------------------------
  Element Icons
 ----------------------------*/
@import "parts/icons";

/*---------------------------
  Element Isotope
 ----------------------------*/
@import "parts/isotope";

/*---------------------------
  Element Action Like
 ----------------------------*/
@import "parts/action-like";


/*------------------------------------------------------------------

  Full Page

 -------------------------------------------------------------------*/
@import "parts/fullpage";

/*------------------------------------------------------------------

  Portfolio

 -------------------------------------------------------------------*/
@import "parts/portfolio";

/*------------------------------------------------------------------

  Blog

 -------------------------------------------------------------------*/
@import "parts/blog";

/*------------------------------------------------------------------

  Store

 -------------------------------------------------------------------*/
@import "parts/store";

/*------------------------------------------------------------------

  Forum

 -------------------------------------------------------------------*/
@import "parts/forum";

/*------------------------------------------------------------------

  Events

 -------------------------------------------------------------------*/
@import "parts/events";

/*------------------------------------------------------------------

  Documentation

 -------------------------------------------------------------------*/
@import "parts/documentation";

/*------------------------------------------------------------------

  Plugins

 -------------------------------------------------------------------*/

/*---------------------------
  Plugin NanoScroller
 ----------------------------*/
@import "parts/plugin-nano-scroller";

/*---------------------------
  Plugin DateTimePicker
 ----------------------------*/
@import "parts/plugin-datetimepicker";
