.nk-image-box,
.nk-image-box-1,
.nk-image-box-1-a,
.nk-image-box-2,
.nk-image-box-3,
.nk-image-box-4,
.nk-image-box-5 {
    position: relative;
    display: block;
    overflow: hidden;

    &,
    &:hover,
    &:focus,
    &:active,
    & a,
    & a:hover,
    & a:focus,
    & a:active {
        text-decoration: none;
    }

    img {
        width: 100%;
        height: auto;
    }
}

// alignment for overlay
.nk-image-box-overlay {
    position: absolute;
    display: flex;
    align-items: flex-start;

    > * {
        width: 100%;
    }

    &.nk-image-box-center {
        align-items: center;
    }
    &.nk-image-box-bottom {
        align-items: flex-end;
    }
}

.nk-image-box-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

// info under image box
.nk-image-box-bottom-info {
    padding: 20px 0;

    .nk-image-box-title,
    .nk-image-box-sub-title {
        margin-bottom: .5rem;
        a {
            color: inherit;
            text-decoration: none;
        }
    }
    .nk-image-box-links {
        font-size: .9rem;
        color: #888;

        a {
            color: inherit;
            text-decoration: none;

            &:hover,
            &.hover,
            &:focus,
            &:active {
                color: #555;
            }
        }
    }
}

// big icon
.nk-image-box-icon {
    font-size: 40px;
}

// icon button
.nk-image-box-icon-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    margin: 6px 4px;
    font-size: 1.15rem;
    line-height: 40px;
    color: inherit;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid;
        border-radius: 30px;
        opacity: .35;
        transition: .2s background-color, .2s border-color, .2s opacity, .2s transform;
        transform: scale(1);
        will-change: color, background-color, opacity, transform;
    }

    &:hover,
    &.hover {
        color: inherit;
        &::after {
            background-color: currentColor;
            border-color: transparent;
            opacity: .1;
            transform: scale(1.15);
        }
    }
}

// big title
.nk-image-box-big-title {
    font-size: 3rem;
    font-weight: 400;

    @media #{$media_xs} {
        font-size: 2.5rem;
    }

    @media #{$media_xxs} {
        font-size: 2rem;
    }
}

/* Style 0 */
.nk-image-box {
    .nk-image-box-overlay {
        top: 45px;
        right: 45px;
        bottom: 45px;
        left: 45px;
    }
    .nk-image-box-title,
    .nk-image-box-sub-title {
        width: 100%;
        margin: 0;
    }
    .nk-image-box-sub-title {
        display: block;
        top: 50%;
        bottom: auto;
        margin-top: 10px;
    }
}

/* Style 1 */
.nk-image-box-1,
.nk-image-box-1-a {
    .nk-image-box-overlay {
        top: 30px;
        right: 30px;
        bottom: 30px;
        left: 30px;
        color: #fff;
        text-align: center;
        background-color: rgba($color_dark_1, .75);
        opacity: 0;
        transition: .4s opacity;
        transform-style: preserve-3d;

        > * {
            padding: 30px;
        }
    }
    &:hover .nk-image-box-overlay,
    &.hover .nk-image-box-overlay {
        opacity: 1;
    }

    .nk-image-box-title,
    .nk-image-box-sub-title {
        width: 100%;
        margin: 0;
    }
    .nk-image-box-sub-title {
        display: block;
        top: 50%;
        bottom: auto;
        margin-top: 10px;
    }
}
.nk-image-box-1-a {
    .nk-image-box-overlay {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: .4s opacity, .4s transform;
        transform: scale(1.1);
    }
    &:hover .nk-image-box-overlay,
    &.hover .nk-image-box-overlay {
        opacity: 1;
        transform: scale(1);
    }
}

/* Style 2 */
.nk-image-box-2 {
    .nk-image-box-overlay {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        color: #fff;
        background-color: rgba($color_dark_1, .2);
        opacity: 1;
        transition: .4s opacity;
        transform-style: preserve-3d;

        > * {
            padding: 30px;
            transition: .4s transform;
            transform: translateY(0);
        }
    }
    &:hover .nk-image-box-overlay,
    &.hover .nk-image-box-overlay {
        opacity: 0;

        > * {
            transform: translateY(7px);
        }
    }
    &.hover:hover .nk-image-box-overlay {
        opacity: 1;

        > * {
            transform: translateY(0);
        }
    }

    .nk-image-box-title,
    .nk-image-box-sub-title {
        width: 100%;
        margin: 0;
    }
    .nk-image-box-sub-title {
        display: block;
        top: 50%;
        bottom: auto;
        margin-top: 10px;
    }
}

/* Style 3 */
.nk-image-box-3 {
    .nk-image-box-overlay {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        color: #fff;
        background: linear-gradient(to top, rgba($color_dark_1, .75) 0%, rgba($color_dark_1, 0) 40%);
        transform-style: preserve-3d;

        &::after {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($color_dark_1, .2);
            transition: .4s opacity;
            z-index: -1;
        }

        > * {
            padding: 30px;
        }
    }
    &:hover .nk-image-box-overlay::after,
    &.hover .nk-image-box-overlay::after {
        opacity: 0;
    }
    &.hover:hover .nk-image-box-overlay::after {
        opacity: 1;
    }

    .nk-image-box-title,
    .nk-image-box-sub-title {
        width: 100%;
        margin: 0;
    }
    .nk-image-box-sub-title {
        display: block;
        top: 50%;
        bottom: auto;
        margin-top: 10px;
    }
}

/* Style 4 */
.nk-image-box-4 {
    transition: .3s box-shadow;
    &:hover,
    &.hover {
        box-shadow: 0 0 35px 0 rgba(#000, .1);
    }

    .nk-image-box-overlay {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        color: #fff;
        text-align: center;
        transition: .4s background-color;

        &::after {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            box-shadow: inset 0 0 0 0 #fff;
            transition: .4s box-shadow;
        }

        > * {
            padding: 60px;
            opacity: 0;
            transition: .4s opacity;
        }
    }
    &:hover .nk-image-box-overlay,
    &.hover .nk-image-box-overlay {
        background-color: rgba($color_dark_1, .75);
        &::after {
            box-shadow: inset 0 0 0 30px #fff;
        }
        > * {
            opacity: 1;
        }
    }

    .nk-image-box-title,
    .nk-image-box-sub-title {
        width: 100%;
        margin: 0;
    }
    .nk-image-box-sub-title {
        display: block;
        top: 50%;
        bottom: auto;
        margin-top: 10px;
    }
}

/* Style 5 */
.nk-image-box-5 {
    .nk-image-box-overlay {
        display: none;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        color: #fff;
        text-align: center;
        background-color: rgba($color_dark_1, .75);

        > * {
            padding: 30px;
        }
    }

    .nk-image-box-title,
    .nk-image-box-sub-title {
        width: 100%;
        margin: 0;
    }
    .nk-image-box-sub-title {
        display: block;
        top: 50%;
        bottom: auto;
        margin-top: 10px;
    }
}
