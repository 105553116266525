// burger icon
.nk-icon-burger {
    position: relative;
    display: inline-block;
    width: 27px;
    height: 24px;

    > span {
        position: absolute;
        display: block;
        top: 50%;
        width: 20px;
        height: 2px;
        margin-top: -1px;
        background-color: currentColor;
        border-radius: 2px;
        transition: .3s .2s width, .3s opacity, .2s transform;
        will-change: width, opacity, transform;
        backface-visibility: hidden;
        transform-style: preserve-3d;
    }

    .nk-t-1 {
        width: 27px;
        margin-top: -7px;
    }
    .nk-t-3 {
        margin-top: 5px;
    }

    // hover animation
    &:hover,
    a:hover > & {
        .nk-t-1 {
            width: 30px;
            animation: nk-icon-burger-animation .3s ease;
        }
        .nk-t-2 {
            width: 23px;
            animation: nk-icon-burger-animation .3s .1s ease;
        }
        .nk-t-3 {
            width: 16px;
            animation: nk-icon-burger-animation .3s .2s ease;
        }
    }

    // active state
    &.active,
    a.active > & {
        > span {
            transition: .3s width, .3s opacity, .3s transform;
            will-change: width, opacity, transform;
        }
        .nk-t-2 {
            opacity: 0;
        }
        .nk-t-1,
        .nk-t-3 {
            width: 23px;
            animation: none;
        }
        .nk-t-1 {
            margin-top: -8px;
            margin-left: 6px;
            transform: rotate(45deg);
            transform-origin: 0 0;
        }
        .nk-t-3 {
            margin-top: 8px;
            margin-left: 6px;
            transform: rotate(-45deg);
            transform-origin: 0 100%;
        }
    }
}
@keyframes nk-icon-burger-animation {
    0%,
    100%   { transform: translateX(0); }
    50%    { transform: translateX(-3px); }
}

// close icon
.nk-icon-close {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;

    &::before,
    &::after {
        content: "";
        position: absolute;
        display: block;
        top: 50%;
        left: 2px;
        width: 27px;
        height: 0;
        border-bottom: 1px solid;
        transition: .3s width, .3s opacity, .3s transform;
        transform-origin: 50% 50%;
        will-change: width, opacity, transform;
        backface-visibility: hidden;
        transform-style: preserve-3d;
    }

    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }
}

// search icon
.nk-icon-search {
    position: relative;
    display: inline-block;
    width: 22px;
    height: 24px;

    &::before,
    &::after {
        content: "";
        position: absolute;
        display: block;
        top: 50%;
        transition: .3s;
        transform: rotate(-45deg);
        transform-style: preserve-3d;
    }
    &::before {
        width: 12px;
        height: 12px;
        margin-top: -8px;
        margin-left: 4px;
        border-radius: 6px;
        box-shadow: inset 0 0 0 2px;
    }
    &::after {
        width: 0;
        height: 6px;
        margin-top: 2px;
        margin-left: 13px;
        border-right: 2px solid;
        transform-origin: 1px 0;
    }

    // hover animation
    &:hover,
    a:hover > & {
        &::before {
            transform: rotate(-45deg) scale(1.2);
        }
        &::after {
            transform: rotate(-40deg) translateX(-1px) translateY(1px);
        }
    }

    // active state
    &.active,
    a.active > & {
        &::before,
        &::after {
            width: 2px;
            height: 23px;
        }
        &::before {
            margin-top: -12px;
            margin-left: 10px;
            transform: rotate(45deg);
        }
        &::after {
            width: 0;
            margin-top: -9px;
            margin-left: 2px;
            transform: rotate(-45deg);
        }
    }
}

// arrow icons
.nk-icon-arrow {
    &-right,
    &-left {
        position: relative;
        display: inline-block;
        width: 26px;
        height: 12px;
        margin-bottom: 12px;
        border-bottom: 2px solid;

        &::after,
        &::before {
            content: "";
            position: absolute;
            display: block;
            bottom: -2px;
            left: -1px;
            width: 50%;
            height: 2px;
            background-color: currentColor;
            border-radius: 2px;
            transition: .2s transform;
            transform-origin: 5% 50%;
            will-change: transform;
        }
        &::after {
            transform: rotate(40deg);
        }
        &::before {
            transform: rotate(-40deg);
        }

        &:hover,
        &.hover,
        :hover > &,
        .hover > & {
            &::after {
                transform: rotate(48deg);
            }
            &::before {
                transform: rotate(-48deg);
            }
        }
    }
    &-right {
        &::after,
        &::before {
            right: -1px;
            left: auto;
            transform-origin: 95% 50%;
        }
    }
}

// video and gif icons
.nk-video-icon,
.nk-video-icon-light,
.nk-gif-icon,
.nk-gif-icon-light {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
    color: #fff;

    &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color_dark_1;
        border-radius: 40px;
        opacity: .85;
        transition: .3s opacity, .3s transform;
        transform-style: preserve-3d;
    }
    > * {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        margin-top: -25px;
        margin-left: -25px;
        font-size: 1.5rem;
        line-height: 51px;
        color: inherit;
        text-align: center;
        opacity: .9;
        transition: .3s transform, .3s opacity;
        transform-style: preserve-3d;

        &.svg-inline--fa {
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -10px;
        }
    }

    &:hover,
    &.hover {
        &::before {
            opacity: .7;
            transform: scale(1.1);
        }
        > * {
            opacity: 1;
            transform: scale(.95);
        }
    }
}
.nk-video-icon-light,
.nk-gif-icon-light {
    color: $color_dark_1;
    &::before {
        background-color: #fff;
    }
}

// video and gif icons style 2

.nk-video-icon-2,
.nk-video-icon-2-light,
.nk-gif-icon-2,
.nk-gif-icon-2-light {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
    color: $color_dark_1;

    &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 5px solid currentColor;
        border-radius: 40px;
        opacity: 1;
        transition: .2s opacity, .2s transform;
        transform-style: preserve-3d;
    }
    > * {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        margin-top: -25px;
        margin-left: -25px;
        font-size: 1.5rem;
        line-height: 51px;
        color: inherit;
        text-align: center;
        opacity: 1;
        transition: .2s transform, .2s opacity;
        transform-style: preserve-3d;

        &.svg-inline--fa {
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -10px;
        }
    }

    &:hover,
    &.hover {
        &::before {
            opacity: .5;
            transform: scale(1.1);
        }
        > * {
            opacity: 1;
            transform: scale(1.3);
        }
    }
}
.nk-video-icon-2-light,
.nk-gif-icon-2-light {
    color: #fff;
}

// loading spinner
$loadingSpinnerSize: 80px !default;
$loadingSpinnerSizeInner: $loadingSpinnerSize * .35 !default;
$loadingSpinnerWeight: 2px !default;
.nk-loading-spinner,
.nk-loading-spinner-light {
    position: relative;
    display: inline-block;
    width: $loadingSpinnerSize;
    height: $loadingSpinnerSize;
    border-radius: 50%;
    box-shadow: inset 0 0 0 $loadingSpinnerWeight $color_dark_1;

    i {
        position: absolute;
        display: block;
        width: $loadingSpinnerSizeInner;
        height: $loadingSpinnerSizeInner;
        overflow: hidden;
        transform-origin: $loadingSpinnerSize / 2 $loadingSpinnerSize / 2;
        animation: nk-loading-spinner .7s infinite linear;

        &::after {
            content: "";
            display: block;
            width: $loadingSpinnerSize;
            height: $loadingSpinnerSize;
            border-radius: 50%;
            box-shadow: inset 0 0 0 $loadingSpinnerWeight rgba(#fff, .8);
        }
    }
}
.nk-loading-spinner-light {
    box-shadow: inset 0 0 0 $loadingSpinnerWeight #fff;
    i::after {
        box-shadow: inset 0 0 0 $loadingSpinnerWeight rgba($color_dark_1, .8);
    }
}
@keyframes nk-loading-spinner {
    100% { transform: rotate(360deg); }
}
