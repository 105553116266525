/* jaldi-regular - latin */
@font-face {
  font-family: 'Jaldi';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts//jaldi-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Jaldi'), local('Jaldi-Regular'),
  url('/assets/fonts//jaldi-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts//jaldi-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts//jaldi-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts//jaldi-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts//jaldi-v5-latin-regular.svg#Jaldi') format('svg'); /* Legacy iOS */
}

/* jaldi-700 - latin */
@font-face {
  font-family: 'Jaldi';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts//jaldi-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Jaldi Bold'), local('Jaldi-Bold'),
  url('/assets/fonts//jaldi-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts//jaldi-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts//jaldi-v5-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts//jaldi-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts//jaldi-v5-latin-700.svg#Jaldi') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts//roboto-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
  url('/assets/fonts//roboto-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts//roboto-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts//roboto-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts//roboto-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts//roboto-v19-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts//roboto-v19-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Italic'), local('Roboto-Italic'),
  url('/assets/fonts//roboto-v19-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts//roboto-v19-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts//roboto-v19-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts//roboto-v19-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts//roboto-v19-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts//roboto-v19-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('/assets/fonts//roboto-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts//roboto-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts//roboto-v19-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts//roboto-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts//roboto-v19-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/montserrat-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url('/assets/fonts/montserrat-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/montserrat-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/montserrat-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/montserrat-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/montserrat-v13-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/montserrat-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
  url('/assets/fonts/montserrat-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/montserrat-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/montserrat-v13-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/montserrat-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/montserrat-v13-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* nunito-sans-regular - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/nunito-sans-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
  url('/assets/fonts/nunito-sans-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/nunito-sans-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/nunito-sans-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/nunito-sans-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/nunito-sans-v4-latin-regular.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-italic - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/nunito-sans-v4-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Italic'), local('NunitoSans-Italic'),
  url('/assets/fonts/nunito-sans-v4-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/nunito-sans-v4-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/nunito-sans-v4-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/nunito-sans-v4-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/nunito-sans-v4-latin-italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-700 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/nunito-sans-v4-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
  url('/assets/fonts/nunito-sans-v4-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/nunito-sans-v4-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/nunito-sans-v4-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/nunito-sans-v4-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/nunito-sans-v4-latin-700.svg#NunitoSans') format('svg'); /* Legacy iOS */
}


h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.display-1,
.display-2,
.display-3,
.display-4,
.nk-mega-item > .dropdown > ul li > label,
.nk-carousel .nk-carousel-prev .nk-carousel-arrow-name,
.nk-carousel .nk-carousel-next .nk-carousel-arrow-name,
.nk-carousel-3 .nk-carousel-prev .nk-carousel-arrow-name,
.nk-carousel-3 .nk-carousel-next .nk-carousel-arrow-name,
.nk-countdown > div > span,
.nk-counter .nk-count,
.nk-counter-2 .nk-count,
.nk-counter-3 .nk-count,
.nk-dropcap-3,
.nk-pricing-1 .nk-pricing-price,
.nk-pricing-2 .nk-pricing-price,
.nk-pricing-3 .nk-pricing-price,
.nk-progress .nk-progress-title,
.nk-page-nav-3 .nk-page-nav-title {
  font-family: 'Jaldi', sans-serif;
}

body,
.nk-sub-title,
.nk-counter-3 .nk-counter-title,
.nk-pricing-2 .nk-pricing-currency,
.nk-pricing-2 .nk-pricing-period,
.nk-pricing-3 .nk-pricing-currency,
.nk-pricing-3 .nk-pricing-period {
  font-family: 'Roboto', sans-serif;
}
