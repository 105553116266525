.nk-widget {
    overflow: hidden;

    ~ .nk-widget {
        margin-top: 65px;
    }

    .nk-widget-title {
        margin-bottom: 25px;
        font-family: inherit;
        font-size: .8rem;
        font-weight: 700;
        text-transform: uppercase;
    }
}

// Posts
.nk-widget-post {
    padding-left: 110px;

    + .nk-widget-post {
        margin-top: 25px;
    }

    &::after {
        content: "";
        display: table;
        clear: both;
    }

    a {
        text-decoration: none;
    }

    .nk-post-image {
        float: left;
        width: 90px;
        margin-left: -110px;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
        }
    }

    .nk-post-title {
        display: inline-block;
        width: 100%;
        margin-top: 6px;
        margin-bottom: 5px;
        font-size: 1.15rem;

        a {
            color: inherit;
            transition: .3s opacity;

            &:hover,
            &:focus {
                opacity: .7;
            }
        }
    }

    .nk-post-meta-date {
        margin-right: 10px;
        font-size: .9rem;
        font-style: italic;
        opacity: .6;

        a {
            color: inherit;
            transition: .3s opacity;

            &:hover,
            &:focus {
                opacity: .7;
            }
        }
    }

    .nk-post-meta-comments {
        i {
            font-size: 1.2rem;
            line-height: 1.2rem;
            vertical-align: middle;
        }

        span {
            font-size: .8rem;
            line-height: .8rem;
            vertical-align: middle;
        }
    }

    // store
    .nk-product-price {
        margin-top: 20px;
    }
}

// Categories
.nk-widget-categories {
    font-size: .9rem;
    &,
    ul {
        padding: 0;
        margin: 0;
        margin-top: -14px;
        list-style-type: none;
    }

    // next level categories
    .nk-widget-categories {
        margin-top: 0;
        margin-left: 25px;

        li::before {
            content: "";
            display: block;
            width: 100%;
            border-top: 1px solid;
            opacity: .1;
        }
    }

    li {
        display: block;

        + li::before {
            content: "";
            display: block;
            width: 100%;
            border-top: 1px solid;
            opacity: .1;
        }

        a {
            display: block;
            padding: 14px 0;
            color: inherit;
            text-decoration: none;
            transition: .3s opacity;

            &:hover,
            &:focus {
                opacity: .7;
            }
        }

        .nk-widget-categories-count {
            float: right;
            color: inherit;
            opacity: .5;
        }
    }
}

// Tags Cloud
.nk-widget-tags,
.nk-post-tags {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-bottom: -10px;
    margin-left: -5px;
    font-size: .7rem;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;

    > a {
        position: relative;
        display: inline-block;
        padding: 5px 11px;
        margin-right: 5px;
        margin-bottom: 10px;
        margin-left: 5px;
        color: inherit;
        text-decoration: none;
        transition: .3s opacity;
        z-index: 1;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: transparent;
            border: 1px solid;
            border-radius: 3px;
            opacity: .25;
            transition: .2s background-color, .2s border-color, .2s opacity;
            will-change: background-color, border-color, opacity;
            z-index: -1;
        }

        &:hover,
        &.hover {
            &::before {
                background-color: currentColor;
                border-color: transparent;
                opacity: .1;
            }
        }
    }
}

// Contact
.nk-widget-contact {
    padding: 0;
    margin: 0;
    list-style-type: none;

    > li {
        position: relative;
        padding-left: 25px;

        + li {
            margin-top: 15px;
        }
    }

    .nk-widget-contact-icon {
        position: absolute;
        left: 0;
        font-size: 1.1rem;
    }
}

// social
.nk-widget-social {
    text-align: center;

    ul {
        align-items: center;

        > li {
            display: inline-flex;
        }
    }

    ul > li > a,
    > a {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        margin: 6px 4px;
        font-size: 1rem;
        color: inherit;
        text-align: center;
        text-decoration: none;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid;
            border-radius: 30px;
            opacity: .35;
            transition: .2s background-color, .2s border-color, .2s opacity, .2s transform;
            transform: scale(1);
            will-change: color, background-color, opacity, transform;
        }

        &:hover,
        &.hover {
            &::after {
                background-color: currentColor;
                border-color: transparent;
                opacity: .1;
                transform: scale(1.15);
            }
        }
    }
}
.nk-widget-social-2 {
    text-align: center;

    ul {
        align-items: center;

        > li {
            display: inline-flex;
        }
    }

    ul > li > a,
    > a {
        display: inline-block;
        margin: 15px;
        color: inherit;
        text-decoration: none;
        transition: .3s opacity;
        will-change: opacity;

        &:hover,
        &.hover {
            opacity: .6;
        }

        > * {
            display: block;
            margin: 0;
        }

        > .icon {
            margin: 0 auto;
            margin-bottom: 10px;
            font-size: 2rem;
        }
    }
}

// Twitter
.nk-twitter {
    position: relative;
    padding-left: 25px;

    + .nk-twitter {
        margin-top: 30px;
    }

    .nk-twitter-date {
        margin-bottom: 10px;
        font-size: .8rem;
        line-height: 1.4;
        opacity: .6;
    }

    .nk-twitter-icon {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.2rem;
    }
}

// Cart
.nk-widget-store-cart {
    h4 {
        margin-bottom: 25px;
    }

    .nk-nav &,
    .nk-navbar-left .nk-nav-icons & {
        width: 364px;
        padding-top: 5px;
        padding-right: 25px;
        padding-bottom: 5px;
        padding-left: 25px;
    }

    .nk-widget-store-cart-actions {
        margin-top: 25px;

        a + a {
            margin-left: 15px;
        }
    }

    .nk-widget-store-cart-total {
        margin-top: 25px;
        margin-bottom: 25px;

        &::before,
        &::after {
            content: "";
            display: table;
            width: 100%;
            clear: both;
            border-top: 1px dotted;
            opacity: .2;
        }
        &::before {
            margin-bottom: 15px;
        }
        &::after {
            margin-top: 15px;
        }

        > a {
            color: inherit;
            text-decoration: none;
            transition: .3s opacity;

            &:hover,
            &:focus {
                opacity: .7;
            }
        }

        > span {
            float: right;
        }
    }
}

/* Sidebars */
.nk-sidebar {
    position: relative;
    height: 100%;
    padding-left: 50px;
    z-index: 1;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -1800px;
        bottom: 0;
        left: 10px;
        background-color: $color_gray_2;
        z-index: -1;
    }

    // fluid
    .container-fluid > .row > div > &.nk-sidebar-left {
        padding-left: 25px;
    }
    .container-fluid > .row > div > &.nk-sidebar-right {
        padding-right: 25px;
    }

    // left
    &.nk-sidebar-left {
        padding-right: 50px;
        padding-left: 0;
        &::after {
            right: 10px;
            left: -1800px;
        }
    }

    @media #{$media_smaller_sm} {
        &,
        &.nk-sidebar-left {
            padding-right: 0 !important; // stylelint-disable-line
            padding-left: 0 !important; // stylelint-disable-line
            &::after {
                right: -1000px;
                left: -1000px;
            }
        }
    }
}
