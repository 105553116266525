.nk-doc-links ul {
    padding: 0;
    list-style: none;
    z-index: 1;

    // main menu items
    > li > div {
        padding: 6.5px 0;
        margin: 10px 0;
        cursor: pointer;
        border-radius: 5px;
        transition: .2s color;

        &:hover {
            color: darken($color_gray_4, 35%);
        }
        &.active {
            color: $color_main_1;
        }
    }

    // child menu items
    > li > ul {
        display: none;
        overflow: hidden;
    }
    > li > ul > li div {
        padding: 5px 0;
        margin-left: 20px;

        &:hover {
            color: darken($color_gray_4, 35%);
        }
        &.active {
            color: $color_main_1;
        }
        &::before {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            margin-top: -1px;
            margin-right: 5px;
            margin-left: 0;
            vertical-align: middle;
            border-bottom: 1px solid;
            transition: .2s width, .2s margin-left;
        }
        &.active::before {
            width: 10px;
            margin-left: 5px;
        }
    }
}
