
/* Style 1 */
.nk-ibox-1 {
    position: relative;
    min-height: 50px;

    .nk-ibox-icon {
        position: absolute;
        width: 50px;
        height: 50px;
        font-size: 1.3rem;
        line-height: 50px;
        text-align: center;
        border-radius: 3px;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid;
            border-radius: 3px;
            opacity: .1;
        }

        &.nk-ibox-icon-circle,
        &.nk-ibox-icon-circle::after {
            border-radius: 25px;
        }
    }

    .nk-ibox-cont {
        margin-left: 75px;
    }

    &.nk-ibox-inverted {
        text-align: right;

        .nk-ibox-cont {
            margin-right: 75px;
            margin-left: 0;
        }
        .nk-ibox-icon {
            right: 0;
        }
    }
}

/* Style 2 */
.nk-ibox-2 {
    position: relative;
    min-height: 30px;

    .nk-ibox-icon {
        position: absolute;
        width: 30px;
        font-size: 2rem;
        line-height: 1;
        text-align: center;
    }

    .nk-ibox-cont {
        padding-top: 5px;
        margin-left: 45px;
    }

    &.nk-ibox-inverted {
        text-align: right;

        .nk-ibox-cont {
            margin-right: 45px;
            margin-left: 0;
        }
        .nk-ibox-icon {
            right: 0;
        }
    }
}

/* Style 3 */
.nk-ibox-3 {
    position: relative;
    text-align: center;

    .nk-ibox-icon {
        position: relative;
        width: 90px;
        height: 90px;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 2rem;
        line-height: 90px;
        text-align: center;
        border-radius: 3px;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid;
            border-radius: 3px;
            opacity: .4;
        }

        &.nk-ibox-icon-circle,
        &.nk-ibox-icon-circle::after {
            border-radius: 45px;
        }
    }

    .nk-ibox-title {
        margin-bottom: 1rem;
    }
}

/* Style 4 */
.nk-ibox-4 {
    position: relative;
    text-align: center;

    .nk-ibox-icon {
        position: relative;
        width: 70px;
        height: 70px;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 4rem;
        line-height: 70px;
        text-align: center;
    }

    .nk-ibox-title {
        margin-bottom: 1rem;
    }
}

/* Titles */
.nk-ibox-title {
    margin-bottom: .5rem;
    font-size: 1.25rem;

    a {
        color: inherit;
        text-decoration: none;
    }
}

/* Feature Hovered + Colors */
.nk-ibox-hover .nk-ibox-icon {
    transition: .3s background-color, .3s color;
    &::after {
        transition: .3s opacity;
    }
}
@each $name, $color in $colors {
    .nk-ibox-icon.nk-ibox-icon-bg-color-#{$name},
    .nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-#{$name},
    .nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-#{$name} {
        color: #fff;
        background-color: $color;
        @if $name == "white" {
            color: $color_dark_1;
        }

        &::after {
            opacity: 0;
        }
    }
    .nk-ibox-icon.nk-ibox-icon-color-#{$name},
    .nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-#{$name},
    .nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-#{$name} {
        color: $color;
    }
}
