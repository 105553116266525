.nk-countdown {
    width: 100%;
    text-align: center;

    > div {
        display: inline-block;
        min-width: 120px;
        padding: 10px 0;

        > span {
            display: block;
            font-family: $font_headings;
            font-size: 4rem;
            font-weight: 600;
        }

        @media #{$media_xs} {
            min-width: 80px;

            > span {
                font-size: 2.4rem;
            }
        }
    }
}
