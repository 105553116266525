.nk-accordion {
    .panel-heading {
        &::after {
            content: "";
            display: block;
            border-bottom: 1px solid;
            opacity: .2;
        }

        a {
            display: block;
            padding: 10px 20px;
            color: inherit;
            text-decoration: none;
        }
    }
}
