.nk-share-place {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: -$share_place_height;
    right: 0;
    left: 0;
    width: 100%;
    height: $share_place_height;
    color: #fff;
    text-align: center;
    background-color: $color_dark_1;
    transition: .2s transform ease-in-out;
    transform: translateY(0);
    z-index: 1007;

    // light
    &.nk-share-place-light {
        color: inherit;
        background-color: #fff;
    }

    // all share buttons
    .nk-share-icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        margin: 0;
        line-height: 2;

        > .nk-share-icon {
            margin: 0 21px;
            font-size: 1.6rem;
            text-decoration: none;
            cursor: pointer;
            opacity: 0;
            transition: .2s opacity ease-out, .2s transform ease-out;
            transform: translateY(-20px);

            // stagger animation
            &:nth-of-type(1) {
                transition-delay: .15s, .15s;
            }
            &:nth-of-type(2) {
                transition-delay: .2s, .2s;
            }
            &:nth-of-type(3) {
                transition-delay: .25s, .25s;
            }
            &:nth-of-type(4) {
                transition-delay: .3s, .3s;
            }
            &:nth-of-type(5) {
                transition-delay: .35s, .35s;
            }
            &:nth-of-type(6) {
                transition-delay: .4s, .4s;
            }
            &:nth-of-type(7) {
                transition-delay: .45s, .45s;
            }

            &::before,
            &::after,
            > * {
                opacity: .85;
                transition: .2s opacity;
                will-change: opacity;
            }

            .social-likes__counter,
            .sociality-share-counter {
                margin-left: 10px;
                font-size: .6em;
                vertical-align: middle;
                opacity: .7;
            }

            &:hover {
                &::before,
                &::after,
                > * {
                    opacity: 1;
                }
            }
        }
    }

    // open
    &.open {
        transform: translateY($share_place_height);

        .nk-share-icons > .nk-share-icon {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

// overlay
.nk-share-place-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    visibility: hidden;
    background-color: rgba($color_dark_1, .6);
    opacity: 0;
    transition: .2s opacity ease-in-out, .2s visibility;
    z-index: 1006;

    .nk-share-place.open ~ & {
        visibility: visible;
        opacity: 1;
    }
}
