.nk-dropcap-circle {
    border-radius: 50%;
}
.nk-dropcap,
.nk-dropcap-2,
.nk-dropcap-3 {
    display: inline-block;
    float: left;
    width: 50px;
    height: 50px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
}
.nk-dropcap {
    margin-right: 15px;
    font-size: 3rem;
}
.nk-dropcap-2 {
    margin-top: 6px;
    margin-right: 15px;
    font-size: 2rem;
    color: #fff;
    background-color: $color_dark_1;
    border-radius: 3px;
}
.nk-dropcap-3 {
    width: 35px;
    height: 40px;
    margin-left: -10px;
    font-family: $font_headings;
    font-size: 6rem;
    font-weight: 600;
    line-height: 4rem;
    pointer-events: none;
    user-select: none;
    opacity: .15;
    z-index: -1;
}
