/* Events List */
.nk-events-list {
    padding: 0;
    margin: 0;
    list-style: none;

    &:hover,
    &.hover {
        > li {
            opacity: .7;
        }
        > li:hover,
        > li.hover {
            opacity: 1;
        }
    }

    > li {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        transition: .3s opacity;

        > * {
            align-self: flex-start;
            width: 100%;
            padding: 20px;
            color: inherit;
            text-decoration: none;
        }

        .nk-event-date {
            width: auto;
            min-width: 120px;
        }
        .nk-event-name {
            flex: 1;
        }
        .nk-event-price {
            align-self: center;
            width: auto;
            min-width: 130px;
        }
        .nk-event-button {
            align-self: center;
            width: auto;
            min-width: 170px;
            font-size: .9rem;
            text-align: center;
        }
        .nk-event-button-sold {
            opacity: .5;
        }
        .nk-event-date,
        .nk-event-name,
        .nk-event-price {
            > span {
                display: block;
                font-size: .9rem;
                opacity: .5;
            }
        }

        + li {
            &::before {
                content: "";
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                left: 0;
                border-bottom: 1px solid;
                opacity: .15;
            }
        }

        @media #{$media_smaller_xs} {
            padding-top: 15px;
            padding-bottom: 15px;

            > * {
                flex: 100%;
                padding-top: 10px;
                padding-bottom: 10px;
            }
            .nk-event-button {
                text-align: left;
            }
        }
    }
}

/* Single Events */
// details
.nk-events-item-details {
    padding: 0;
    padding-top: 30px;
    margin: 0;
    text-align: center;
    list-style: none;

    &::after {
        content: "";
        display: block;
        border-bottom: 1px solid;
        opacity: .1;
    }

    > li {
        display: inline-block;
        padding-right: 20px;
        padding-bottom: 30px;
        padding-left: 20px;

        .icon {
            margin-right: 10px;
        }

        a {
            color: inherit;
            text-decoration: none;
            transition: .3s color;

            &:hover,
            &.hover,
            &:focus,
            &:active {
                color: $color_main_1;
            }
        }
    }

    .nk-header-title & {
        width: 100%;
        background-color: rgba($color_dark_1, .4);
    }
}

/* Vertical Details */
.nk-events-item-details-vertical {
    padding: 0;
    padding-bottom: 10px;
    text-align: left;

    &::after {
        content: none;
    }

    &::before {
        content: "";
        display: block;
        margin-top: 30px;
        margin-bottom: 10px;
        border-top: 1px solid;
        opacity: .1;
    }

    > li {
        display: block;
        padding: 0;
        padding-top: 20px;
    }
}
