.nk-footer {
    position: relative;
    overflow: hidden;
    color: #eee;
    background-color: $color_dark_2;
    z-index: 1;
}

.nk-footer-logo {
    text-align: center;
}

.nk-footer-social {
    text-align: center;

    > a {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: 6px 4px;
        font-size: 1.15rem;
        line-height: 40px;
        color: inherit;
        border: 1px solid;
        border-radius: 20px;
        transition: .3s color, .3s background-color, .3s border-color;
        will-change: color, background-color;

        &:hover,
        &.hover {
            color: $color_dark_1;
            background-color: #fff;
            border-color: #fff;
        }
    }
    &.nk-footer-social-inverted {
        > a {
            &:hover,
            &.hover {
                color: #fff;
                background-color: $color_dark_1;
                border-color: $color_dark_1;
            }
        }
    }
}

.nk-copyright,
.nk-copyright-2 {
    font-size: .95rem;
    color: rgba(#fff, .7);
    a {
        color: #fff;
    }
}
.nk-copyright {
    padding: 2rem 0;
    background-color: rgba($color_dark_1, .6);
}
