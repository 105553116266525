.nk-pagination {
    display: block;

    // arrows
    .nk-pagination-prev,
    .nk-pagination-next {
        position: relative;
        display: inline-block;
        min-height: 65px;
        padding: 17px 0;
        color: inherit;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;

        &.disabled {
            pointer-events: none;
            cursor: default;
            opacity: .2;
        }

        .nk-icon-arrow-right,
        .nk-icon-arrow-left {
            position: absolute;
            top: 20px;
        }
        .nk-icon-arrow-right {
            right: 0;
        }
        .nk-icon-arrow-left {
            left: 0;
        }
    }
    .nk-pagination-prev {
        float: left;
        padding-left: 45px;
        text-align: left;
    }
    .nk-pagination-next {
        float: right;
        padding-right: 45px;
        text-align: right;
    }

    // nav
    nav {
        display: inline-block;
        font-weight: 600;
        text-align: center;
        vertical-align: middle;

        > * {
            display: inline-block;
            padding: 9px 12px;

            &.nk-pagination-current,
            &.nk-pagination-current-white {
                width: 34px;
                height: 34px;
                padding: 6px;
                line-height: 34px - 6px * 2;
                color: #fff;
                background-color: $color_dark_1;
                border-radius: 17px;

                &:hover {
                    opacity: 1;
                }
            }
            &.nk-pagination-current-white {
                color: $color_dark_1;
                background-color: #fff;
            }
        }
        a {
            color: inherit;
            text-decoration: none;
            transition: .3s opacity;

            &:hover {
                opacity: .6;
            }
        }
    }

    // position
    &.nk-pagination-center,
    &.nk-pagination-left,
    &.nk-pagination-right {
        .nk-pagination-prev,
        .nk-pagination-next {
            float: none;
        }
    }
    &.nk-pagination-center {
        text-align: center;
    }
    &.nk-pagination-left {
        text-align: left;
    }
    &.nk-pagination-right {
        text-align: right;
    }
}
